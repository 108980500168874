import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import { startCase } from 'lodash'

const Products = ({ data, pageContext }) => {
  const products = data.allContentfulProduct.edges
  const { basePath } = pageContext
  let ogImage

  try {
    ogImage = products[0].node.heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout>
      <SEO title={startCase(basePath)} image={ogImage} />
      <StyledContainer>
        <CardList>
          {products.map(({ node: product }) => (
            <div>
              <Link to={`./${product.slug}`}>{product.name}</Link>
            </div>
          ))}
        </CardList>
      </StyledContainer>
      <Pagination context={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulProduct(
      sort: { fields: [name], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          name
          id
          slug
        }
      }
    }
  }
`

export default Products

const StyledContainer = styled(Container)`
  margin-top: 144px;
`
